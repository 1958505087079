import type { UploadItem } from '@/types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import localforageLib from 'localforage'

/** Options object for configuring a request queue. Clients in a window context
 * vs a service worker will need different options. */
export interface RequestQueueConfig {
	/** Axios instance to use for resolving requests */
	axiosInstance: AxiosInstance

	/** Method to register a background sync event */
	requestBackgroundSync: (tag: string) => Promise<void>
}

/** Set up a request queue and return its public methods.
 * Multiple queues can work independently given different idbNames
 * See _docs/sync.md */
export function useRequestQueue<T>(
	idbName: string,
	configOptions: RequestQueueConfig
) {
	const broadcastChannel = new BroadcastChannel('retry-queue:' + idbName)
	const config = configOptions
	const localforage = localforageLib.createInstance({
		name: 'request-queue',
		storeName: idbName,
	})
	// We retry requests with the "sending" status because the window
	// might have been closed while the request was underway
	const statusesToRetry = ['sending', 'failed', 'waitingForNetwork']

	/** In-memory list of queued requests */
	let queue: { [key: string]: UploadItem<T> } = {}

	/** function to get the status of a request by id */
	const getRequestStatus = (id: string) => structuredClone(queue[id])

	/** Load the status of all requests from storage */
	async function loadAll() {
		queue = {}
		const records = await localforage.keys()
		for (const record of records) {
			const item = await localforage.getItem<UploadItem<T>>(record)
			if (item) {
				queue[record] = item
			}
		}
	}

	/** Initialize the request queue */
	async function initialize() {
		await loadAll()

		// Listen for events and track changes in our queue
		broadcastChannel.addEventListener('message', (event) => {
			if (event.data) {
				const { id, uploadItem } = event.data
				queue[id] = uploadItem
			}
		})
	}

	/** Save a record of a new request.	*/
	async function createRecord(id: string, data: UploadItem<T>) {
		queue[id] = data
		broadcastChannel.postMessage({ id, uploadItem: data })

		await localforage.setItem<UploadItem<T>>(id, data)
	}

	/** Update an item in the queue */
	async function updateRecord(id: string, data: Partial<UploadItem<T>>) {
		// Todo: Write this in a transaction with IndexedDB
		const record = await localforage.getItem<UploadItem<T>>(id)
		if (!record) {
			throw new Error('Record not found: ' + id)
		}

		queue[id] = { ...record, ...data }

		broadcastChannel.postMessage({
			type: 'retryEvent',
			instanceName: idbName,
			id,
			uploadItem: { ...record, ...data },
		})
		await localforage.setItem<UploadItem<T>>(id, { ...record, ...data })
	}

	/** Save a request to retry later */
	async function enqueue(id: string, requestConfig: AxiosRequestConfig<T>) {
		// Update storage with the request
		await createRecord(id, {
			uploadStatus: 'waitingForNetwork',
			updatedAt: new Date().toUTCString(),
			uploadAttemptedAt: new Date().toUTCString(),
			requestConfig: requestConfig,
		})

		try {
			await config.requestBackgroundSync('retry-queue')
			console.log('Background Sync registered')
		} catch {
			console.warn(
				'Background Sync could not be registered. We will retry the request on refresh.'
			)
		}
	}

	/** Retry a given request from the queue */
	async function retry(id: string) {
		await initializationPromise

		const record = queue[id]
		if (!record) {
			throw new Error('Record not found: ' + id)
		}

		if (!statusesToRetry.includes(record.uploadStatus)) {
			throw new Error('Invalid upload status: ' + record.uploadStatus)
		}

		// Update storage with the request
		await updateRecord(id, {
			uploadStatus: 'sending',
			uploadAttemptedAt: new Date().toUTCString(),
			uploadError: undefined,
		})

		return executeRequest(id, queue[id].requestConfig)
	}

	/** Retry all requests in the queue, aborting if there is a network error */
	async function retryAll() {
		// Reload the queue from storage
		await loadAll()

		for (const id in queue) {
			const record = queue[id]
			if (statusesToRetry.includes(record.uploadStatus)) {
				try {
					await updateRecord(id, {
						uploadStatus: 'sending',
						uploadAttemptedAt: new Date().toUTCString(),
						uploadError: undefined,
					})

					const success = await executeRequest(id, record.requestConfig)
					if (!success) {
						// The request had a network error. Mark it and quit
						await updateRecord(id, {
							uploadStatus: 'waitingForNetwork',
							uploadError: undefined,
						})
						return
					}
				} catch (error: any) {
					// This request failed. Mark it as failed and continue
					await updateRecord(id, {
						uploadStatus: 'failed',
						uploadError: error.message,
					})

					throw error
				}
			}
		}
	}

	/** Execute a request using a specified AxiosRequestConfig.
	 * Update the record using the given id. */
	async function executeRequest(
		id: string,
		requestConfig: AxiosRequestConfig<T>
	) {
		const request = structuredClone(requestConfig)

		try {
			// Attempt the request
			await config.axiosInstance.request(request)
			updateRecord(id, {
				uploadedAt: new Date().toUTCString(),
				uploadStatus: 'complete',
				uploadError: undefined,
			})

			return true
		} catch (error: any) {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				await updateRecord(id, {
					uploadStatus: 'failed',
					uploadError: error.response.data.message,
				})
				throw error
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.warn('Network issue')
				await enqueue(id, request)
				return false
			} else {
				// Something happened in setting up the request that triggered an Error
				console.warn('Unrecoverable Error', error.message)
				throw error
			}
		}
	}

	/** Attempt a request. If it fails, add it to the queue */
	async function addRequest(
		id: string,
		requestConfig: AxiosRequestConfig<T>
	): Promise<any> {
		await initializationPromise

		// Record that the request is in progress
		await createRecord(id, {
			updatedAt: new Date().toUTCString(),
			requestConfig: requestConfig,
			uploadAttemptedAt: new Date().toUTCString(),
			uploadStatus: 'sending',
		})

		await executeRequest(id, requestConfig)
	}

	// Begin loading all queued requests
	const initializationPromise = initialize()

	return {
		addRequest,
		retry,
		retryAll,
		getRequestStatus,
	}
}
